body {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  background-image: url(/assets/images/portfolios/background2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}

/* Tablet breakpoint */
@media (max-width: 768px) {
  body {
    background-size: cover;
    background-position: center top;
  }
}


@media (max-width: 480px) {
  body {
    background-size: cover;
    background-position: center top;
  }

}
