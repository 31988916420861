html, body {
  height: 100%;
  margin: 0;
}

.content-page-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 100%;
  background-image: url(/assets/images/portfolios/background2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.left-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ruru-pic {
  height: 45vh;
  width: 45vh;
  border: solid 2px #f2efa8;
  background-color: #f2efa8;
  border-radius: 1.3em;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px;
}

.extra-notes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-bullet-points {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 21px;
  background-color: whitesmoke;
  border-radius: 1.5em;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  padding: 30px;
  width: 95%;
}

.bullet-point-group {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 2em;
  color: $teal;
}

.text {
  font-size: 1.5em;
  font-weight: 900;
  margin-left: 21px;
}

/* Tablet breakpoint */
@media (max-width: 768px) {
  .content-page-wrapper {
    flex-direction: column; /* Stacks columns in a single column layout */
    height: auto; /* Adjust height when stacked */
  }

  .right-column {
    padding: 50px; /* Less padding on smaller screens */
  }

  .contact-bullet-points {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .bullet-point-group {
    .icon {
      font-size: 1.5em; /* Smaller icons on tablet */
    }

    .text {
      font-size: 1.2em; /* Smaller text on tablet */
    }

    .contact {
      margin-right: 0px;
    } 
  }
}

/* Mobile breakpoint */
@media (max-width: 480px) {
  .content-page-wrapper {
    flex-direction: column; /* Full-width layout on mobile */
    padding: 10px;
  }

  .right-column {
    padding: 30px; /* Minimal padding on mobile */
  }

  .contact-bullet-points {
    padding: 15px;
  }

  .bullet-point-group {
    .icon {
      font-size: 1.2em; /* Even smaller icons on mobile */
    }

    .text {
      font-size: 1em; /* Smaller text for mobile devices */
    }

    .contact {
      margin-right: 0px;
    } 
  }
}
