html, body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-container {
  flex-grow: 1;
  padding-bottom: 5vh; // Adds space above the footer
}

.footer-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 30px;
  background-color: #e1c1ff;
  min-height: 4.5vh;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-links-container {
  width: 30vw;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  height: 22px;
  border-bottom: 1px solid transparent;
  transition: 0.5s ease-in-out;
  margin-right: 20px;
  text-transform: uppercase;
  font-size: 1.1rem;

  a {
    color: #4a2a69;
    text-decoration: none;
    &:hover {
      color: #f9d3f3;
      transition: 0.5s ease-in-out;
    }
  }
}

@media (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: static; // Non-fixed footer on smaller screens
  }

  .footer-links-container {
    width: 80vw;
  }

  .footer-link-wrapper {
    font-size: 1rem;
    margin-right: 15px;
  }
}

@media (max-width: 480px) {
  .footer-wrapper {
    padding: 15px;
  }

  .footer-links-container {
    width: 90vw;
    flex-direction: column;
    align-items: center;
  }

  .footer-link-wrapper {
    margin: 5px 0;
    font-size: 0.9rem;
  }
}
