.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: #B695C0;
  background-image: url(/assets/images/portfolios/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 2px solid $charcoal;
  z-index: 10000 !important;
  position: relative;

  .left-side {
    .hamburger-menu {
      display: none; 
      background: none;
      border: none;
      font-size: 1.8rem; /* Default size */
      cursor: pointer;
      color: $teal;
      margin-right: 15px;
      z-index: 9999;
    }

    .nav-image {
      padding-right: 25px;
    }
  }

  .middle-side {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      font-size: 2rem;
      letter-spacing: 5px;
      color: black;
      -webkit-text-stroke: 1px $charcoal;

      @media (max-width: 768px) {
        font-size: 1.2rem;
        letter-spacing: 3px;
      }

      @media (max-width: 480px) {
        width: 200px;
        font-size: 1.0rem;
        letter-spacing: 2px;
      }
    }

    .title {
      font-size: 1.0rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }

      @media (max-width: 480px) {
        font-size: 0.9rem;
      }
    }
  }

  .right-side {
    width: 20vw;
  }

  .nav-link-container {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 1.2rem;

    .nav-link-wrapper a {
      color: black;
      text-decoration: none;
      padding: 5px 8px;

      &:hover {
        color: #fde5b3;
        background-color: rgba(255, 255, 255, 0.1);
        border-bottom: 4px solid #fde5b3;
        border-radius: 8px;
        transition: 0.5s ease-in-out;
      }
    }

    .nav-link-active {
      color: #fdbeb0;
      border-bottom: 4px solid black;
    }
  }

  @media (max-width: 768px) {
    .left-side .hamburger-menu {
      display: block;
      z-index: 9999 !important;
      font-size: 1.5rem;
    }

    .nav-link-container {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 6vh;
      left: 0;
      width: 200px;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 20px;
      border-radius: 0 8px 8px 0;

      &.show {
        display: flex;
      }

      .nav-link-wrapper {
        margin-bottom: 10px;
        width: 100%;
        
        a {
          color: #fde5b3;
          font-size: 1.2rem;
          text-decoration: none;
          padding: 10px 0;
          display: block;
          text-align: left;

          &:hover {
            color: #fde5b3;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
          }
        }

        .nav-link-active {
          color: #fdbeb0;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .nav-link-container {
      .nav-link-wrapper a {
        font-size: 0.6rem; /* Smaller font size for smaller screens */
        padding: 2px 0; /* Adjust padding for better spacing */
      }
    }
  }
}
