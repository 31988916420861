.homepage-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: -1px;

  .filter-links {
    display: flex;
    justify-content: space-around;

    .btn {
      width: 100%;
      &:hover {
        border: solid 1px $charcoal;
      }
    }
  }
}


@media (max-width: 768px) {
  .homepage-wrapper {
    padding: 20px;
  }

  .filter-links {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .btn {
    width: 45%;
    font-size: 0.95rem;
  }
}


@media (max-width: 480px) {
  .filter-links {
    flex-direction: column;
    align-items: center;
  }

  .btn {
    width: 80%;
    font-size: 0.9rem;
  }
}
