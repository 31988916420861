$teal: #6B478F;
$dark-teal: #9e62a0;
$charcoal: #42454a;
$offwhite: #f6f6f6;
$smoke: #e0dada;
$blue: #008dff;
$warning: #922a2a;
$grey: #F2F2F2;
$lightpink: #7A5299;
$thistle: #D8BFD8;
$slateblue:#6A5ACD;
$ghostwhite: #F8F8FF;
$palegreen:#98FB98;