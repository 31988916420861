.portfolio-items-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  .portfolio-item-wrapper {
    position: relative;

    .portfolio-img-background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 351px;
      width: 100%;
    }

    .img-text-wrapper {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      padding-left: 100px;
      padding-right: 100px;

      .project-title {
        transition: 1s ease-in-out;
        color: transparent;
      }

      .subtitle {
        transition: 1s ease-in-out;
        color: transparent;
      }
    }

    .img-text-wrapper:hover .subtitle {
      color: $teal;
      font-weight: 400;
    }

    .img-text-wrapper:hover .project-title {
      color: $offwhite;
      font-size: 2em;
    }

    .read-more {
      transition: 2s ease-in-out;
      color: transparent;
    }

    .img-text-wrapper:hover .read-more {
      color: $offwhite;
      font-weight: 500;
    }

    .logo-wrapper img {
      width: 50%;
      margin-bottom: 20px;
    }

    .portfolio-img-background.image-blur {
      filter: brightness(10%);
      transition: 1s;
    }
  }
}

@media (max-width: 768px) {
  .portfolio-items-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .filter-links {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .btn {
    width: 45%;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .portfolio-items-wrapper {
    grid-template-columns: 1fr;
  }

  .filter-links {
    flex-direction: column;
    align-items: center;
  }

  .btn {
    width: 80%;
    font-size: 0.9rem;
  }

  .portfolio-item-wrapper {
    .img-text-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
