.portfolio-detail-wrapper {
  .portfolio-detail-top {
    display: flex;
    justify-content: space-evenly;
    margin-top: 55px;
    flex-wrap: wrap;

    .portfolio-detail-left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 750px;
      padding: 10px;

      .portfolio-item-attributes {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        font-size: 1.3rem;
        flex-wrap: wrap;
      }

      .attribute {
        border: solid 2px transparent;
        background-color: #f7f6d9;
        border-radius: 0.4em;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        padding: 10px;
        margin: 5px;
      }

      .featured-image {
        width: 100%;
        max-width: 640px;
        height: auto;
        aspect-ratio: 4 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        background-color: black;

        .bubble-indicators {
          display: flex;
          justify-content: center;
          gap: 10px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
        }

        .bubble {
          width: 12px;
          height: 12px;
          background-color: #ccc;
          border-radius: 50%;
          transition: background-color 0.3s ease;
          cursor: pointer;

          &.active {
            background-color: $teal;
          }

          &:hover {
            background-color: darken($teal, 15%);
          }
        }

        .image-gallery-slide {
          height: 100%;
          overflow: hidden;
        }
      }

      .site-link {
        margin: 10px;
        padding: 15px;
        background-color: $teal;
        border-radius: 3px;
        text-decoration: none;
        color: white;
        transition: 0.5s ease-in-out;
        font-size: 1.2em;
    
        &:hover {
          background-color: $dark-teal;
        }
      }
    }
  }

  .portfolio-detail-right {
    width: 100%;
    max-width: 750px;
    padding: 10px;

    .portfolio-detail-description-wrapper {
      text-align: left;
      display: flex;
      justify-content: center;
      white-space: pre-wrap;

      .top-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        border-radius: 1.5em;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        padding: 20px;
        width: 100%;
      }
      
      .name {
        font-size: 2.5em;
        margin-bottom: 20px;
        text-align: center;
      }

      .description {
        margin: 20px;
        font-size: 1.1em;
      }
    }
  }

  .portfolio-detail-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px 0;
  }

  .information {
    width: 100%;
    max-width: 750px;
    background-color: whitesmoke;
    border-radius: 1.5em;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    padding: 40px;
    margin-bottom: 90px;
  }

  /* Tablet breakpoint */
  @media (max-width: 768px) {
    .portfolio-detail-top {
      flex-direction: column;
      align-items: center;

      .portfolio-detail-left,
      .portfolio-detail-right {
        max-width: 90%;
        padding: 0;
      }

      .portfolio-item-attributes {
        font-size: 1.1rem;
      }
    }

    .portfolio-detail-right .name {
      font-size: 2em;
    }
  }

  /* Mobile breakpoint */
  @media (max-width: 480px) {
    .portfolio-detail-top {
      flex-direction: column;

      .portfolio-item-attributes {
        font-size: 1rem;
        flex-direction: column;
        align-items: center;
      }
    }

    .featured-image {
      max-width: 100%;
      height: auto;
      box-shadow: none;
    }

    .portfolio-detail-right .name {
      font-size: 1.8em;
    }

    .information {
      padding: 20px;
    }
  }
}
